import styles from '../styles/Video.module.css'



const src = "https://youtu.be/PhPm1Meo3o0";

const Video = () => {
  return (
    <iframe
      className={styles.container}
      
      height="450"
      src={src}
      title="Youtube Player"
      frameborder="0"
      allowFullScreen
    />
  );
};

export default Video;